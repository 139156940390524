<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增服务范围</el-button>
			</el-row>
			<!-- 服务范围表单 -->
			<el-table :data="rangeData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="range_id" label="ID"></el-table-column>
				<el-table-column prop="range_name" label="范围名称"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="已关联服务">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.service_list" :key="index" style="margin-left: 5px;">{{item.service_name}}</el-tag>
          </template>
        </el-table-column>
				<el-table-column prop="create_time" label="创建时间" width="200"></el-table-column>
				<el-table-column prop="create_by" label="创建人" width="120"></el-table-column>
				<el-table-column label="操作" width="180">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row,$event)" v-if="scope.row.service_list.length===0">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="600px" :visible.sync="addLevelDialog">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="100px" :rules="addlevelRules">
					<el-form-item label="范围名称" prop="range_name">
						<el-input v-model="addlevelForm.range_name" maxlength="10" show-word-limit placeholder="请输入服务范围名称"></el-input>
					</el-form-item>
					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
  let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
  import ImageUpload from '@/components/Upload/ImageUpload.vue'
	export default {
		components: {
			Pagination,ImageUpload
		},
		data() {
			return {
				title: "新增服务范围",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					range_name: ''
				},
				addlevelRules: {
					range_name: [{required: true, message: "请输入服务范围名称", trigger: "blur"}, ]
				},
				rangeData: []
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getServiceRange();
			}
		},
		methods: {
			getServiceRange() {
				var url = 'service_range/range_list';
				let params = {
					range_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.rangeData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增服务范围
			addLevel() {
				this.title = "新增服务范围";
				this.addlevelForm = {range_name: ''};
				this.addLevelDialog = true
			},
			//确认新增
			editNewLevel() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'service_range/addOrUpdate';
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getServiceRange();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						},1000);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑服务范围";
				this.addLevelDialog = true
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k]
				}
        console.log(this.addlevelForm)
			},
			//删除
			removeLevel(item,e) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'service_range/deleteRange';
					let params = {
						range_id: item.range_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
              //this.rangeData = [];
							this.getServiceRange();
              e.target.parentNode.blur()
              e.target.blur()
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					// this.$message.info('已取消删除')
          // console.log(e)
          e.target.parentNode.blur()
          e.target.blur()
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getServiceRange();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getServiceRange();
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import './Service_range.scss';
</style>